import { useState, useEffect } from "react";
import { VStack, Box, Image, Select, Button, Container, useToast, Input, Text } from '@chakra-ui/react';
import logo from "../images/kft-logo.png";
import axios from "axios";
import Cookies from 'js-cookie';
import Login from "./login";

const Dashboard = () => {

  const token = Cookies.get("access_token");

  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [employeeInputs, setEmployeeInputs] = useState([0]);

  const [selectedBarrack, setSelectedBarrack] = useState("");

  const [lastName, setLastName] = useState("");
  const [employees, setEmployees] = useState([]);
  const [barracks, setBarracks] = useState([]);

  const [pin, setPin] = useState('');

  const toast = useToast();
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [geolocationEnabled, setGeolocationEnabled] = useState(true);//false
  const [geolocationPermission, setGeolocationPermission] = useState("prompt");

  /*useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
        setGeolocationPermission(permissionStatus.state);
        if(permissionStatus.state === "prompt"){
          navigator.geolocation.getCurrentPosition(async (position) => {
            const { latitude, longitude } = position.coords;
            setLatitude(latitude);
            setLongitude(longitude);
          });
        }
        permissionStatus.onchange = () => {
          setGeolocationPermission(permissionStatus.state);
          if(permissionStatus.state === "denied") {
            showGeoError();
          } 
        };
        if(permissionStatus.state === "denied") {
          showGeoError();
        } 
      });
    }
  }, []);

  useEffect(() => {
    if (geolocationEnabled && geolocationPermission === "granted" && selectedBarrack === "") {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        setLatitude(latitude);
        setLongitude(longitude);

        const nearestBarrack = await axios.post("/api/location", { latitude: latitude, longitude: longitude, code: token });
        if(selectedBarrack === ""){
          setSelectedBarrack(nearestBarrack.data.barrack[0].name);
        }
      }, (error) => {
        showToast("error", "Fehler beim Abrufen der Standortdaten. Bitte versuche es erneut.");
      });
    }
  }, [geolocationEnabled, geolocationPermission, selectedBarrack, token]);

  useEffect(() => {
    if (geolocationPermission === "granted") {
      setGeolocationEnabled(true);
    } else {
      setGeolocationEnabled(false);
    }
  }, [geolocationPermission]);
  
  function showGeoError(){
    showToast("error", "Die Standortdaten sind deaktiviert. Bitte aktiviere diese in den Einstellungen.", 30000);
  }*/

  navigator.geolocation.getCurrentPosition(async (position) => {
    const { latitude, longitude } = position.coords;
    setLatitude(latitude);
    setLongitude(longitude);

    const nearestBarrack = await axios.post("/api/location", { latitude: latitude, longitude: longitude, code: token });
    if(selectedBarrack === ""){
      setSelectedBarrack(nearestBarrack.data.barrack[0].name);
    }
  });

  useEffect(() => {

    const handleKeyDown = (event) => {
      if (document.activeElement.tagName === 'BODY') {
        if (/[0-9]/.test(event.key)) {
          const newPin = pin + event.key;
          if (newPin.length === 4) {
            checkPin(newPin);
          } else {
            setPin(newPin);
          }
        }
      }else{
        setPin('');
      }
    };

    const checkPin = async (keyPin) => {
      axios({
        url: '/api/download',
        method: 'POST',
        responseType: 'blob',
        data: {pin: keyPin},
        headers: {
          Authorization: token
        }
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Mitarbeiter Zeiterfassung.xlsx');
          document.body.appendChild(link);
          link.click();
        });
      setPin('');
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [pin, token]);

  useEffect(() => {
    async function fetchEmployeeData() {
      const employeeResponse = await axios.get("/api/employees", { params: { code: token } });
      const barrackResponse = await axios.get("/api/barracks", { params: { code: token } });
      setEmployees(employeeResponse.data);
      setBarracks(barrackResponse.data);
    }
    fetchEmployeeData();
  }, [token]);

  function showToast(type,message,duration){
    toast({
      description: message,
      status: type,
      position: "top",
      duration: duration || 10000,
      isClosable: true,
    });
  }
 
  async function handleEmployee(event, index) {
    const { value } = event.target;
    if(value.length > 0){
      const newSelectedEmployees = [...selectedEmployees];
      const existingEmployee = employees.find((employee) => employee.name === value);

      if (existingEmployee) {
        newSelectedEmployees[index] = existingEmployee.name;
      }/* else {
        if(lastName !== value){
          setLastName(value);
        } else {
          //await axios.post("/api/employee", { name: value, code: token });
          //showToast("info", "Der Mitarbeiter '" + value + "' wurde erfolgreich neu angelegt.");

          //const employeeResponse = await axios.get("/api/employees", { params: { code: token } });
          //setEmployees(employeeResponse.data);

        } 
        newSelectedEmployees[index] = value;
      }*/

      setSelectedEmployees(newSelectedEmployees);
    }else{
      const newSelectedEmployees = [...selectedEmployees];
      newSelectedEmployees[index] = "";
      setSelectedEmployees(newSelectedEmployees);
    }
  }

  function addEmployeeInput() {
    setEmployeeInputs([...employeeInputs, employeeInputs.length]);
  }

  function handleBarrack(event) {
    setSelectedBarrack(event.target.value);
  }

  async function handleSubmit() {
    /*if (!geolocationEnabled || longitude === 0 || latitude === 0) {
      showToast("error", "Die Standortdaten konnten nicht abgefragt werden, bitte lade die Seite neu.", 30000);
      return;
    }*/
    if (selectedEmployees.length === 0 || selectedEmployees.some((employee) => !employee)) {
      showToast("error", "Bitte wähle mindestens einen Mitarbeiter aus.");
      return;
    }
  
    if (selectedBarrack.length === 0) {
      showToast("error", "Bitte wähle eine Baracke aus.");
      return;
    }
  
    for (const employeeName of selectedEmployees) {
      const employeeIndex = await axios.get("/api/employee", { params: { name: employeeName, code: token } });
      
      const usedEmployeeName = (employeeIndex.data.name !== undefined) ? employeeIndex.data.name : employeeName;

      const resultStart = await axios.post("/api/start", { latitude: latitude, longitude: longitude, employee: employeeIndex.data.id, employeeName: usedEmployeeName, barrack: selectedBarrack, code: token });
      const updatedBarrack = resultStart.data.updated;
      const success = resultStart.data.success;
      if(updatedBarrack !== false && success === true) {
        showToast("warning", "Mitarbeiter " + employeeName + " wurde in der Baracke " + updatedBarrack + " abgemeldet.");
      }
      if(success === true){
        showToast("success", "Mitarbeiter " + employeeName + " wurde erfolgreich in der Baracke " + selectedBarrack + " eingeschrieben.");
      }else{
        showToast("error", "Mitarbeiter " + employeeName + " konnte nicht in der Baracke " + selectedBarrack + " eingeschrieben werden (Mitarbeiter ist bereits in Baracke '" + selectedBarrack + "' eingeschrieben).");
      }

    }

    const employeeInputElements = document.querySelectorAll("input[name^='employee']");
    employeeInputElements.forEach((element) => {
      element.value = "";
    });
    
    setSelectedBarrack("");
    setSelectedEmployees([]);
    setEmployeeInputs([0]);
  
  }

  if(token === undefined) {
    return (<Login/>);
    // Ja das ist alles extrem blöd Zukunft EDV!
  }

  return (
    <Container p={4} maxW="xl" centerContent>
      <Image src={logo} mt={8} mb={8}/>
      <VStack spacing={4} w="100%">
        {employeeInputs.map((inputIndex) => (
          <Box key={inputIndex} w="100%">
            <Input
              type="text"
              id={`employee${inputIndex}`}
              name={`employee${inputIndex}`}
              list="employees"
              //value={selectedEmployees[inputIndex] || ""}
              onChange={(e) => handleEmployee(e, inputIndex)}
              onBlur={(e) => handleEmployee(e, inputIndex)}
              size="md"
              w="100%"
              mb={6}
              bg="white"
              placeholder="Mitarbeiter auswählen *"
            />
          </Box>
        ))}
        <Button onClick={addEmployeeInput}>Weiteren Mitarbeiter hinzufügen</Button>
        <datalist id="employees">
          {employees.map((employee) => (
            <option key={employee.id} value={employee.name} />
          ))}
        </datalist>

          <Select value={selectedBarrack} onChange={handleBarrack} size="md" w="100%" mb={16} bg="white">
            <option value="" disabled>
              Baracke auswählen *
            </option>
            {barracks.map((barrack,index) => (
              <option key={index} value={barrack.name}>{barrack.name}</option>
            ))}
          </Select>
          <Button
            w="200px"
            h="200px"
            p={4}
            borderRadius={100}
            fontSize="4xl"
            color="white"
            bg="green.400"
            _hover={{ bg: "green.500" }}
            onClick={handleSubmit}
          >
            Start
          </Button>
          </VStack>
      </Container>
  );
}

export default Dashboard;
