import React from "react";
import { Box, Flex } from "@chakra-ui/react";

const DefaultLayout = ({ children }) => {
  return (
    <Flex fontFamily={{base: "Roboto Thin", md: "Roboto Regular"}} fontWeight={{base: 900, md: 500}}>
      <Box w="100%">
        {children}
      </Box>
    </Flex>
  );
};

export default DefaultLayout;
