import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Center,
  FormControl,
  FormLabel,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie";
import {useNavigate} from 'react-router-dom';

function Login() {
  const [code, setCode] = useState("");
  const firstInputRef = useRef();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        "/api/login",
        { code }
      );
      const token = response.data.token;
      Cookies.set("access_token", token);
      navigate("/schlechtesterCodeEver");

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  return (
    <Center height="100vh">
      <Box>
        <FormControl>
          <FormLabel>Bitte den Code eingeben:</FormLabel>
          <PinInput
            value={code}
            onChange={(value) => setCode(value)}
            onComplete={handleSubmit}
            size="lg"
          >
            <PinInputField ref={firstInputRef} />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </FormControl>
      </Box>
    </Center>
  );
}

export default Login;
