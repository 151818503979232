import Dashboard from './pages/Dashboard';
import InvalidLayout from './layouts/InvalidLayout';

const publicRoutes = [
    { path: '/', component: <Dashboard/>},
    { path: '*', component: Dashboard, layout: InvalidLayout }
];

//TODO Hier müsste man ggf. das SecurityLayout ansprechen + die / route von oben entfernen und unten einfügen ( private = nur mit Login erreichbar )
const privateRoutes = [

];

export { publicRoutes, privateRoutes };