import React, {Fragment} from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { v4 as uuid } from 'uuid';

import reportWebVitals from './reportWebVitals';

import { privateRoutes, publicRoutes } from './routes';
import DefaultLayout from './layouts/DefaultLayout';
import SecurityLayout from './layouts/SecurityLayout';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';

export default function App() {

    const theme = extendTheme({
        fonts: {
            thin: "Roboto Thin, sans-serif",
            light: "Roboto Light, sans-serif",
            regular: "Roboto Regular, sans-serif",
            medium: "Roboto Medium, sans-serif",
            bold: "Roboto Bold, sans-serif",
            black: "Roboto Black, sans-serif",
        },
        components: {
            Tabs: {
                variants: {
                    line: {
                        tab: {
                            px: "0.75rem",
                            py: "0.2rem"
                        }
                    }
                }
            }
        },
        styles: {
            global: {
                "@font-face": [
                    {
                        fontFamily: "Roboto Thin",
                        src: `url("/fonts/Roboto-Thin.ttf") format("truetype")`,
                        fontWeight: "100",
                        fontStyle: "normal",
                        fontDisplay: "swap",
                    },
                    {
                        fontFamily: "Roboto Light",
                        src: `url("/fonts/Roboto-Light.ttf") format("truetype")`,
                        fontWeight: "300",
                        fontStyle: "normal",
                        fontDisplay: "swap",
                    },
                    {
                        fontFamily: "Roboto Regular",
                        src: `url("/fonts/Roboto-Regular.ttf") format("truetype")`,
                        fontWeight: "400",
                        fontStyle: "normal",
                        fontDisplay: "swap",
                    },
                    {
                        fontFamily: "Roboto Medium",
                        src: `url("/fonts/Roboto-Medium.ttf") format("truetype")`,
                        fontWeight: "500",
                        fontStyle: "normal",
                        fontDisplay: "swap",
                    },
                    {
                        fontFamily: "Roboto Bold",
                        src: `url("/fonts/Roboto-Bold.ttf") format("truetype")`,
                        fontWeight: "700",
                        fontStyle: "normal",
                        fontDisplay: "swap",
                    },
                    {
                        fontFamily: "Roboto Black",
                        src: `url("/fonts/Roboto-Black.ttf") format("truetype")`,
                        fontWeight: "900",
                        fontStyle: "normal",
                        fontDisplay: "swap",
                    }
                  ],
                "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    borderRadius: "3px",
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    borderRadius: "3px",
                },
                body: {
                    bg: "gray.100"
                }
            }
        }
        });

  return (
        
          <BrowserRouter>
              <ChakraProvider theme={theme}>
                      <Routes>
                          {privateRoutes.map((route) => {
                              const Page = route.component;
                              let Layout = DefaultLayout;

                              if (route.layout === null) {
                                  Layout = Fragment;
                              } else if (route.layout) {
                                  Layout = route.layout;
                              }

                              return (
                                  <Route
                                      key={uuid()}
                                      path={route.path}
                                      element={
                                          <SecurityLayout>
                                              <Layout menu={route.menu}>
                                                {Page}
                                              </Layout>
                                          </SecurityLayout>
                                      }
                                  />
                              );
                          })}
                          {publicRoutes.map((route) => {
                              const Page = route.component;
                              let Layout = DefaultLayout;

                              if (route.layout === null) {
                                  Layout = Fragment;
                              } else if (route.layout) {
                                  Layout = route.layout;
                              }

                              return (
                                  <Route
                                      key={uuid()}
                                      path={route.path}
                                      element={
                                          <Layout menu={route.menu}>
                                              {Page}
                                          </Layout>
                                      }
                                  />
                              );
                          })}
                      </Routes>
              </ChakraProvider>
          </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App/>);

reportWebVitals(console.log);