import React, {useEffect} from "react";
import Cookies from "js-cookie";

export default function SecurityLayout({ children }) {

    const token = Cookies.get("access_token");

    useEffect(() => {
        if (token === undefined) {
            window.location.href = '/login';
        }
    }, [token]);

    return (token !== undefined ? children : <div />);
}
